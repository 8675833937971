import { onMounted, ref, watch } from "@vue/composition-api";
import footerDataTables from "@/fake-db/footerDataTables.json";
import { api } from "@/services/api";
import { removeComma } from "@/services/filters"
import moment from "moment/moment";

export default function useReportSale() {
    const textSearch = ref('')
    const XLSX = require('xlsx')
    const headers = ref([
        {
            text: '#',
            value: 'number',
            width: '70',
        },
        {
            text: 'รหัสสินค้า',
            value: 'product_code',
            width: '120',
        },
        {
            text: 'ชื่อสินค้า',
            value: 'product_name',
            width: '200',
        },
        {
            text: 'รับเข้า',
            value: 'store_product_import',
            width: '120',
            align: 'end',
        },
        {
            text: 'ราคาทุน',
            value: 'quantity',
            width: '130',
            align: 'end',
        },
        {
            text: 'ขายออก',
            value: 'amount',
            width: '120',
            align: 'end',
        },
        {
            text: 'ราคาขาย',
            value: 'total',
            width: '130',
            align: 'end',
        },
        {
            text: 'กำไร',
            value: 'profit',
            width: '130',
            align: 'end',
        },

    ])
    const dataList = ref([])
    const itemsPerPage = ref(-1)
    const status = ref('1')
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const isShowDetail = ref(false)
    const storeList = ref([])
    const storeSelection = ref('')
    const totalBalance = ref('')
    const exportLoading = ref(false)
    const shopList = ref([])
    const shopSelection = ref('')
    const moment = require('moment')
    const start = ref(moment(new Date()).format('YYYY-MM-DD'))
    const end = ref(moment(new Date()).format('YYYY-MM-DD'))
    const getShop = () => {
        api.get({
            path: 'admin/shops',
        }).then(({ data }) => {
            shopList.value = data
            shopList.value.unshift({
                shop_id: '',
                shop_name: 'ทั้งหมด',
            })
            shopSelection.value = shopList.value[0].shop_id
        }).catch(err => {
            console.log('error : ', err)
        })

    }

    const getReport = () => {
        loading.value = true
        api.get({
            path: '/admin/reportProduct',
            param: `?shop_id=${ shopSelection.value }&search=${ textSearch.value }&start=${ start.value }&end=${ end.value }&currency_id=${ localStorage.getItem('currencyId') }`,
        }).then(({ data, total }) => {
            totalBalance.value = total
            mapData(data)
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    const mapData = (data) => {
        dataList.value = data.map((item, index) => {
            return {
                number: index + 1,
                ...item,
            }
        })
        dataList.value.push({
            total: 'รวม',
            profit: totalBalance.value,
        })

    }

    const exportExcel = async () => {
        exportLoading.value = true
        const fileName = `รายงานสรุปยอดขาย.xlsx`
        const Heading = [
            [`รายงานสรุปยอดขาย`],
            [
                '#',
                `รหัสสินค้า`,
                'ชื่อสินค้า',
                'รับเข้า',
                'ราคาทุน',
                'ขายออก',
                `ราคาขาย`,
                `กำไร`,
            ],
        ]

        const ws = XLSX.utils.aoa_to_sheet(Heading)

        const dataExport = await removeComma(JSON.parse(JSON.stringify(dataList.value)))
        XLSX.utils.sheet_add_json(ws, dataExport, {
            header: [
                'number',
                'product_code',
                'product_name',
                'store_product_import',
                'quantity',
                'amount',
                'total',
                'profit',
            ],
            skipHeader: true,
            origin: -1,
        })
        const wb = XLSX.utils.book_new()
        const merge = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
        ]
        const wscols = [
            { wch: 6 },
            { wch: 10 },
            { wch: 26 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
        ]
        ws['!merges'] = merge
        ws['!cols'] = wscols
        XLSX.utils.book_append_sheet(
            wb,
            ws,
            `รายงานสรุปยอดขาย`,
        )

        XLSX.writeFile(wb, fileName)
        setTimeout(() => {
            exportLoading.value = false
        }, 1500)
    }

    onMounted(() => {
        getShop()
        getReport()
    })


    const addPayload = (dateStart, dateEnd) => {
        start.value = dateStart
        end.value = dateEnd
        getReport()
    }

    return {
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isShowDetail,
        isEdit,
        dataEdit,
        footer,
        isUpdateStatus,
        status,
        dataList,
        loading,
        storeList,
        storeSelection,
        shopList,
        shopSelection,
        exportLoading,
        addPayload,
        exportExcel,
        getReport,
    }
}