<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <span>รายงานสรุปยอดขาย</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="exportExcel" :loading="exportLoading" :disabled="loading">ออกรายงาน</v-btn>
      </v-card-title>
      <DateFilters :default-status="1" @onSendDate='addPayload'/>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getReport'
                        label='ค้นหาสินค้า'
                        dense
                        hide-details
                        placeholder='คำค้นหา'>

          </v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <v-select :items="shopList" v-model="shopSelection" no-data-text="ไม่มีข้อมูล" label="เลือกร้านค้า" filled
                    dense
                    item-text="shop_name"
                    item-value="shop_id"
                    @change="getReport"
                    hide-details
          />
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          hide-default-footer
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.total`]='{ item }'>
          <span :class="item.total ==='รวม'?'primary--text font-weight-bold':''">{{
              item.total
            }}
          </span>
        </template>
        <template #[`item.profit`]='{ item }'>
          <span :class="item.total ==='รวม'?'primary--text font-weight-bold':''">{{
              item.profit
            }}
                     <CurrentCurrency/>
          </span>
        </template>
        <template #[`item.total`]='{ item }'>
          {{ item.total }}
          <CurrentCurrency v-if="item.total!='รวม'"/>
        </template>
        <template #[`item.quantity`]='{ item }'>
          {{ item.quantity }}
          <CurrentCurrency v-if="item.total!='รวม'"/>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import useReportSale from "./useReportSale";
import CurrentCurrency from "@/components/CurrentCurrency";
import DateFilters from "@/components/DateFilters";

export default {
  props: {},
  components: { CurrentCurrency, DateFilters },
  setup(_, { root, emit }) {
    return { ...useReportSale() }
  },

}
</script>

<style scoped>

</style>
